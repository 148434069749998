@import './colors';
@import 'main.v5';
$fif-tile-size: 16.875rem;

$max-content-width: 90rem;
$max-tab-bar-width: 90rem;

$mobile-width: 780px;
$small-desktop-width: 970px;
$ccpa-width: 1215px;

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0.75rem;
    font-weight: 100;
}

hr {
    box-sizing: border-box;
    height: 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #979797;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin: 0 auto;
}

// global header classes
.hide-me {
    display: none;
}

// pixels to rem
@function pToR($pixels) {
    @return $pixels/16 * 1rem;
}

@mixin input-font() {
    font-family: inherit;
    font-size: 0.927rem;
    letter-spacing: 0.058rem;
    line-height: 1.5rem;
    padding: 0 16px 0;
}

@mixin text($size, $letterSpacing, $lineHeight, $color, $fontFamily) {
    font-size: pToR($size);
    letter-spacing: $letterSpacing;
    line-height: $lineHeight;
    color: $color;
    font-family: $fontFamily;
}

@mixin lincoln-underline-overlay() {
    position: absolute;
    height: 2px;
    border-bottom: 2px solid $brand-border;
    z-index: 2;
    width: 100%;
    bottom: 0;
    left: 0;
}

@mixin lincoln-primary-button() {
    height: pToR(64);
    width: fit-content;
    min-width: pToR(106);
    border-radius: pToR(4);
    background-color: $lincoln-reimagine-primary-dark;
    color: white;
    padding: pToR(8) pToR(20);
    font-family: $proxima-nova-bold;
    font-size: pToR(14);
    line-height: pToR(20);
    letter-spacing: normal;

    .fds-icon {
        display: none;
    }

    &:hover {
        background-color: $lincoln-reimagine-primary-dark-hover;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: $fds-reimagine-disabled-background-color;
        color: $fds-reimagine-disabled-text-color;
    }

    &:focus-visible {
        outline: 2px solid black;
        outline-offset: 2px;
    }
}

@mixin ford-secondary-button-default() {
    display: flex;
    justify-content: center;
    font-family: $ford-f1-font-bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;
    color: $fds-preimagine-primary-blue;
    border-radius: 400px;
    padding: 12px 20px;
    border: 1px solid $fds-preimagine-primary-blue;
    height: 40px;
    min-width: 93px;
    width: fit-content;

    &:hover {
        color: $fds-preimagine-primary-button-hover;
        text-decoration: none;
    }
    &:focus-visible {
        text-decoration: none;
        outline: 2px solid $fds-preimagine-primary-blue;
        outline-offset: 2px;
    }
    .secondary-button-text {
        display: inline-flex;
        align-items: center;
    }
}

.main-overlay {
    display: none;
    position: fixed;
    top: 5.25rem;
    left: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: #000 none;

    &.open {
        display: block;
    }
}

.text-center {
    text-align: center;
}

.section-heading-link {
    text-decoration: none;
    color: $text-gray;

    @include mobile {
        font-size: pToR(34);
        line-height: pToR(44);
        letter-spacing: 4px;
    }
}
