@import 'styles/main.v5';

.app {
    overflow: hidden;
}
body {
    &.open {
        height: 100vh;
        overflow-y: hidden;
        margin-right: pToR(15);

        .fgx-brand-css {
            transform: translateX(0);
        }
    }
    &.gdpr-open,
    &.subnav-open {
        height: 100vh;
        overflow-y: hidden;
    }

    #_evidon-message {
        a {
            color: $grabber-blue !important;
        }
    }
}
#root {
    position: relative;
}
#global-ux + #root {
    * {
        -webkit-font-smoothing: antialiased;
    }
}

.open {
    #global-ux + #root {
        .modal {
            @include tablet-v5 {
                padding: 0 0 pToR(96);
            }
        }
    }
}
// Mexico & South America syndicated header/footer overrides
#global-ux {
    font-size: pToR(16);

    .open & {
        position: relative;
        z-index: 5;
    }

    .fixed-header,
    .footerComponent {
        max-width: pToR(1440);
        margin: auto;
    }

    .footerComponent .overlayHolder {
        z-index: 100000;
    }

    ul,
    ol,
    dl {
        li {
            font-size: pToR(16);
        }
    }

    .main-nav {
        .site-links {
            .site-link-title {
                letter-spacing: normal;
            }
        }
        .categories {
            .category-header {
                font-size: pToR(21);
                line-height: pToR(29.4);
            }
            .category-inner {
                h3,
                .richtext.section p a {
                    font-size: pToR(16);
                    line-height: pToR(22.4);
                }
            }
        }
    }
    .footer {
        .row {
            max-width: pToR(1440);
        }

        .footer-links {
            .footer-links-column {
                h3,
                p span {
                    font-size: pToR(16);
                }
                .footer-content {
                    li a {
                        font-size: pToR(14);
                    }
                }
            }
        }
        a:link {
            font-size: pToR(14);
        }
        .footer-middle .footer-social-share .richtext.section p {
            font-size: pToR(16);
        }
        .footer-bottom {
            .footer-bottom-copyright {
                a:link,
                ul li {
                    font-size: pToR(12);
                }
            }
        }
    }
    &:first-of-type {
        @media (min-width: 320px) and (max-width: 768px) {
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: 767px) {
        .main-nav {
            .site-links {
                a {
                    font-size: pToR(15);
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: 0;

        .main-nav {
            padding: pToR(18) pToR(34.8) 0 pToR(77);

            .site-links {
                a {
                    padding: 0 pToR(10);
                }
            }
            .categories .category-inner {
                h3 {
                    font-size: pToR(21);
                    margin: 0 0 pToR(15) 0;
                    padding: pToR(15) 0;
                }
                .richtext.section p a {
                    padding: 3px 0;
                    font-size: pToR(15);
                    line-height: pToR(22.4);
                }
            }
        }
        .footer {
            .footer-links {
                .footer-links-column {
                    h3 {
                        font-size: pToR(16);
                        line-height: pToR(17.6);
                        padding: pToR(20) 0;
                    }
                    .regular li a {
                        font-size: pToR(14);
                        line-height: pToR(22.4);
                    }
                    &.single ul li {
                        padding: 0 0 pToR(5) 0;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .main-nav {
            .site-links {
                margin-top: 0;
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1380px) {
        .main-nav {
            .site-links {
                font-size: pToR(16);

                li.site-link-chevron a {
                    padding: 0 pToR(5);
                }
            }
        }
    }
    @media only screen and (min-width: 992px) {
        .main-nav {
            .categories {
                .category-inner {
                    h3 {
                        font-size: pToR(24);
                        line-height: pToR(26.4);
                    }
                }
            }
        }
    }
}

.fmc-button {
    height: pToR(40);
    border-width: pToR(1);
    padding: 0 pToR(32);
    border-radius: pToR(20);
    width: max-content;
    display: flex;
    justify-content: center;

    [data-brand='lincoln'] & {
        height: pToR(50);
        border-width: pToR(2);
        padding: 0 pToR(20);
        --icon-gap: pToR(8);
        --icon-offset: pToR(-5);
        --icon-size: pToR(7.5);
    }
}
.fmc-text-button {
    --icon-offset: 15px;
    --chevron-size: 16px;
    --underline-weight: 1px;
    --underline-offset: 3px;
    --height: 25px;

    [data-brand='lincoln'] & {
        --chevron-size: 24px;
    }

    &:hover,
    &:focus {
        background-color: transparent;

        .brand-ford & {
            color: var(--fds-color--secondary);
        }
        [data-brand='lincoln'] & {
            --underline-weight: 2px;
            --underline-offset: 8px;
            --chevron-size: 24px;
        }
    }
}

.dynamic-dropdown-container {
    .dynamic-dropdown-input-group {
        .fmc-input {
            border: 1px solid $fds-color--gray2;
            padding-left: pToR(16);
        }
    }
}
.fgx-brand-css {
    letter-spacing: normal;

    .fordMainNavigationR23 {
        .navbar {
            &.navbar-static-top {
                min-width: 100%;
            }
        }
    }
}
.fgx-pantry-bootstrap.fgx-brand-css {
    @include desktop {
        .fordMainNavigationR23 {
            .main-nav-items {
                &:last-of-type {
                    transform: translateX(12px);
                }

                &.navbar-nav {
                    width: calc(50% - 52.5px);
                }
                &.utility-list.inherit-nav-width {
                    width: calc(50% - 52.5px);
                }
            }
        }
    }
}

.limit-1440 {
    .brand-ford & {
        @include md-desktop {
            max-width: pToR(1440);
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.fgx-pantry-bootstrap.fgx-brand-css.global-owner-syndicated-lincoln-footer,
.fgx-pantry-bootstrap.fgx-brand-css.global-owner-syndicated-ford-footer,
.globalFooter.fgx-brand-print-hide {
    @media print {
        display: none !important;
    }

    .disclosures {
        @media print {
            display: none !important;
        }
    }
}
