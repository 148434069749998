$font-weight-500: 500;
$font-weight-400: 400;
$font-weight-300: 300;
$font-weight-200: 200;
$font-weight-100: 100;

@font-face {
    font-family: FordAntenna;
    font-display: swap;
    src: url('./antenna/FordAntennaWGL-Regular.woff2') format('woff2');
}
@font-face {
    font-family: FordAntennaMedium;
    font-display: swap;
    font-weight: $font-weight-500;
    src: url('./antenna/antenna-medium.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaItalic;
    font-display: swap;
    font-weight: $font-weight-400;
    src: url('./antenna/FordAntennaWGL-RegularItalic.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaLight;
    font-display: swap;
    font-weight: $font-weight-200;
    src: url('./antenna/FordAntennaWGL-Light.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaExtraLight;
    font-display: swap;
    font-weight: $font-weight-100;
    src: url('./antenna/FordAntennaWGL-ExtraLight.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaRegular;
    font-display: swap;
    src: url('./antenna/FordAntennaWGL-Regular.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaCondensedRegular;
    font-display: swap;
    font-weight: $font-weight-300;
    src: url('./antenna/FordAntennaCondWGL-Reg.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaCondensedBold;
    font-display: swap;
    font-weight: $font-weight-100;
    src: url('./antenna/FordAntennaCondWGL-Bold.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaCondensedBlack;
    font-display: swap;
    font-weight: $font-weight-100;
    src: url('./antenna/FordAntennaCondWGL-Black.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaCondensedMedium;
    font-display: swap;
    font-weight: $font-weight-100;
    src: url('./antenna/FordAntennaCondWGL-Med.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaCondensedLight;
    font-display: swap;
    font-weight: $font-weight-100;
    src: url('./antenna/FordAntennaCondWGL-Light.woff2') format('woff2');
}

@font-face {
    font-family: FordAntennaSemibold;
    font-display: swap;
    font-weight: normal;
    src: url('./antenna/FordAntennaWGL-Semibold.woff2') format('woff2');
}

@font-face {
    font-family: FdsFordIcons;
    font-display: swap;
    src: url('./ford-icons/ford-icons.woff?tnd5m') format('woff');
}

@font-face {
    font-family: FordF1Bold;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Bold.woff2') format('woff2');
}

@font-face {
    font-family: FordF1Semibold;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Semibold.woff2') format('woff2');
}

@font-face {
    font-family: FordF1Light;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Light.woff2') format('woff2');
}

@font-face {
    font-family: FordF1Regular;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Regular.woff2') format('woff2');
}

@font-face {
    font-family: FordF1Medium;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Medium.woff2') format('woff2');
}
@font-face {
    font-family: FordMotion;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Bold.woff2') format('woff2');
    font-weight: 700;
}
@font-face {
    font-family: FordMotion;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Semibold.woff2') format('woff2');
    font-weight: 600;
}
@font-face {
    font-family: FordMotion;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Medium.woff2') format('woff2');
    font-weight: 500;
}
@font-face {
    font-family: FordMotion;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Regular.woff2') format('woff2');
    font-weight: 400;
}
@font-face {
    font-family: FordMotion;
    font-display: swap;
    src: url('./f1-fonts/FordF-1-Light.woff2') format('woff2');
    font-weight: 300;
}

$fds-chevron-right: '\e904';
$fds-chevron-dot: '\e902';
$fds-chevron-right-unfilled: '\e90c';
$fds-chevron-left-unfilled: '\e90d';
$fds-warning-icon-filled: '\e990';
$fds-success-icon-filled: '\e90f';

$roboto-font: 'Roboto', Arial, sans-serif;
$antenna-font: FordAntenna, sans-serif;
$antenna-font-reg-italic: FordAntennaItalic, sans-serif;
$antenna-font-light: FordAntennaLight, sans-serif;
$antenna-font-regular: FordAntennaRegular, Arial, Helvetica, sans-serif;
$antenna-font-antenna-light: FordAntennaCond-Light, Arial, Helvetica, sans-serif;
$antenna-font-antenna: FordAntenna, Arial, Helvetica, sans-serif;
$antenna-font-extra-light: FordAntennaExtraLight, sans-serif;
$antenna-font-condensed-regular: FordAntennaCondensedRegular, 'Arial',
    'Helvetica', sans-serif;
$antenna-font-condensed-black: FordAntennaCondensedBlack, sans-serif;
$antenna-font-condensed-bold: FordAntennaCondensedBold, sans-serif;
$antenna-font-condensed-medium: FordAntennaCondensedMedium, 'Arial', 'Helvetica',
    sans-serif;
$antenna-font-condensed-light: FordAntennaCondensedLight, 'Arial', 'Helvetica',
    sans-serif;
$antenna-font-medium: FordAntennaMedium, 'Arial', 'Helvetica', sans-serif;
$antenna-font-Cond-antenna-regular: FordAntennaCond, Ford Antenna Cond, Arial,
    Helvetica, sans-serif;

$ford-f1-font-bold: FordF1Bold, sans-serif;
$ford-f1-font-light: FordF1Light, sans-serif;
$ford-f1-font-regular: FordF1Regular, sans-serif;
$ford-f1-font-medium: FordF1Medium, sans-serif;
$ford-f1-font-semibold: FordF1Semibold, sans-serif;
