@import '@styles/main.scss';

.notification-container {
    display: flex;
    height: pToR(73);
    padding: pToR(14) pToR(8);
    border: pToR(1) solid $fds-preimagine-primary-blue;
    border-left-width: pToR(8);
    border-radius: pToR(4);
    letter-spacing: normal;

    @include mobile-v5 {
        height: auto;
    }

    &.rte {
        height: auto;
    }

    &.info-status {
        background-color: #e6f1fd;
    }
    &.success-status {
        background-color: #e5f3e6;
    }
    &.error-status {
        background-color: $fds-preimagine-danger-subtle;
    }

    &.no-border {
        border: none;
        border-radius: 0;
        padding-right: pToR(16);
        padding-left: pToR(16);
    }

    &.no-sub-copy {
        height: auto;
        padding-top: pToR(18);
    }
}

.notification-icon {
    margin-right: 10px;
    align-self: flex-start;
}

.notification-content {
    display: flex;
    flex-direction: column;
}

.notification-main-copy {
    font-family: $ford-f1-font-bold;
    line-height: pToR(24);
}

.notification-sub-copy {
    font-family: $ford-f1-font-medium;
    line-height: pToR(24);
}

.notification-cta-text {
    text-decoration: underline;
    text-decoration-color: $fds-preimagine-dark-text;
    color: inherit;

    &:focus {
        outline: 2px auto $fds-preimagine-primary-blue;
        outline-offset: 2px;
    }
}

.notification-close-button {
    align-self: flex-start;
    margin-left: auto;

    &:focus {
        outline: 2px auto $fds-preimagine-primary-blue;
        outline-offset: 2px;
    }
}

.charger-adapter-banner__notification-container {
    min-height: pToR(80);
    max-width: calc(100% - 32px);
    margin: pToR(24) auto pToR(7);

    @include tablet {
        min-height: pToR(56);
        max-width: pToR(1440);
        padding: 0 pToR(56);
    }

    .notification-container.no-border {
        min-height: fit-content;
        flex-direction: row;
        align-items: center;
        padding: pToR(16);
        gap: pToR(16);

        @include tablet {
            height: pToR(56);
        }

        .notification-tesla-banner {
            p {
                color: $charcoal;
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;

                a {
                    color: inherit;
                    font-family: $ford-f1-font-bold;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }
            }
        }
        a,
        .notification-close-button {
            &:focus {
                outline: unset;
            }
            &:focus-visible {
                outline: 1px solid $fds-reimagine-secondary-blue;
                outline-offset: 2px;
            }
        }
        &.is-canada {
            .notification-tesla-banner {
                p,
                a {
                    font-family: $ford-f1-font-regular;
                }
            }
        }
        .notification-close-button {
            height: pToR(24);
        }
        .notification-icon {
            margin-right: 0;
        }
        .notification-icon,
        .notification-close-button {
            align-self: center;

            @include mobile {
                align-self: flex-start;
            }
        }
    }
}
