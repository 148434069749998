@import '@styles/main.v5';

.smart-tile-grid {
    background-color: $white;
    margin: pToR(48) auto 0;
    text-align: center;
    position: relative;
    color: $text-gray;

    @include mobile {
        padding-top: pToR(67.2);
        padding-bottom: pToR(80);
        margin: 0 auto;
    }
    @include desktop {
        padding-bottom: 0;
    }

    .no-vehicles & {
        @include tablet-v5 {
            margin-top: pToR(50);
        }
    }

    .brand-lincoln & {
        background-color: $gray;

        @include media('<414px') {
            .fmc-type--subtitle1 {
                font-size: pToR(13);
                line-height: pToR(20);
                overflow-wrap: break-word;
            }
        }
    }

    .brand-ford & {
        padding: 0 pToR(16) pToR(48);

        @include tablet {
            padding: 0 pToR(56) pToR(48);
        }

        .smart-tile__clickable {
            a:focus-visible {
                border-radius: 12px;
            }
        }
    }

    .brand-ford.na & {
        padding-bottom: pToR(64);

        @include tablet {
            padding-bottom: pToR(96);
        }
    }

    .fds-layout-grid {
        .brand-ford & {
            padding: 0;

            @include tablet-v5 {
                max-width: 2160px;
            }
        }

        @include tablet-v5 {
            padding-bottom: pToR(95);
        }
    }

    .fds-layout-grid__inner {
        @include mobile-v5 {
            display: grid;
            grid-template-columns: repeat(2, 178px);
            grid-gap: pToR(16);
        }
        @include media('<414px') {
            display: grid;
            grid-template-columns: repeat(2, 132px);
            grid-gap: pToR(16);
        }
        @include desktop {
            max-width: pToR(1236);
            margin: auto;
        }
    }

    h2 {
        letter-spacing: normal;
        font-weight: var(--fmc-type__weight-medium);

        .brand-ford & {
            font-family: $ford-f1-font-semibold;
            color: $fds-preimagine-high-contrast;
            font-size: pToR(24);
            line-height: pToR(32);
            letter-spacing: normal;
            text-align: start;
            margin-bottom: pToR(16);

            @include mobile {
                font-size: pToR(20);
                line-height: pToR(28);
            }

            @include tablet {
                margin-bottom: pToR(22);
            }
        }

        .brand-lincoln & {
            font-size: pToR(32);
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            letter-spacing: pToR(2);
            line-height: pToR(42);

            @include mobile {
                line-height: pToR(34);
            }
        }
    }

    .grid-title {
        font-family: $antenna-font-condensed-light;
        font-size: pToR(48);
        letter-spacing: pToR(3);
        line-height: pToR(58);
        text-transform: uppercase;
    }

    .smart-tile-grid {
        display: flex;
        flex-direction: column;
        margin: pToR(22) auto;

        @include tablet {
            margin: 0 auto pToR(16);
            max-width: pToR(1300);
            flex-flow: row wrap;
            justify-content: center;
            padding-top: 0;
        }

        @include mobile {
            padding-top: pToR(16);
            margin: unset;
            display: flex;
            align-items: center;
        }
    }

    .information-grid {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .brand-lincoln & {
            @include mobile-v5 {
                display: grid;
                grid-template-columns: repeat(2, 178px);
                grid-gap: pToR(16);
            }
            @include media('<414px') {
                display: grid;
                grid-template-columns: repeat(2, 132px);
                grid-gap: pToR(16);
            }
            @include tablet-v5 {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: pToR(16);
                max-width: pToR(808);
                margin: auto;
            }
        }

        .brand-ford & {
            @include mobile {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: pToR(16);
            }
            @include tablet {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: pToR(16);
                grid-row-gap: pToR(16);
            }
        }

        & > div {
            width: 100%;

            .brand-ford & {
                min-height: 64px;

                @include tablet {
                    margin-bottom: 0;
                }
            }

            .brand-lincoln & {
                max-width: pToR(396);
                max-height: pToR(302);
                margin-bottom: pToR(9.6);

                @include mobile {
                    margin-bottom: pToR(2);
                }
            }
        }

        @include mobile {
            padding: 0;
        }

        @include desktop {
            display: grid;
            grid-gap: pToR(24);
            grid-column-gap: pToR(16);

            .brand-lincoln & {
                grid-template-columns: repeat(2, 396px);
            }
        }

        .brand-lincoln & {
            @include md-desktop {
                grid-template-columns: repeat(3, 396px);
            }
        }
    }
}

.lg-margin {
    .no-carousel-market & {
        margin-top: unset;

        @include mobile-v5 {
            .fmc-type--heading5 {
                padding-top: pToR(42);
            }
        }
        @include tablet-v5 {
            .fmc-type--heading3 {
                padding-top: pToR(50);
            }
        }
    }

    @include mobile {
        padding-top: pToR(12);
        margin-top: pToR(250);

        .brand-ford & {
            margin-top: pToR(236);
        }
        .dynamic-masthead-state & {
            margin-top: pToR(283);
        }
    }

    @include tablet {
        margin-top: pToR(275);

        .brand-ford & {
            margin-top: pToR(116);
        }

        .dynamic-masthead-state & {
            margin-top: pToR(355);
        }
    }
}

.no-carousel {
    .brand-ford & {
        margin-top: unset;

        .no-carousel-market & {
            @include mobile-v5 {
                .fmc-type--heading5 {
                    padding-top: pToR(42);
                }
            }
        }
    }
}

.no-indicators-margin {
    margin-top: pToR(250);

    .brand-lincoln {
        margin-top: unset;
    }

    @include tablet-v5 {
        .dynamic-masthead-state.has-vehicles & {
            margin-top: pToR(310);
        }
    }

    .no-carousel-market & {
        @include tablet-v5 {
            margin-top: pToR(50);
        }
    }
}
