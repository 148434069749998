@import '@styles/main.v5';

.return-to-dealer {
    &-container {
        display: flex;
        flex-direction: column;

        .return-to-dealer-component {
            &-container {
                display: flex;
                max-width: pToR(1105);
                width: 100%;
                flex-direction: column;
                margin: auto;

                .brand-lincoln & {
                    margin-top: pToR(10);
                }

                @include mobile {
                    max-width: -webkit-fill-available;
                    max-width: -moz-available;
                    margin-left: pToR(16);
                    margin-right: pToR(16);
                }

                @include tablet {
                    padding-left: pToR(58);
                    padding-right: pToR(58);
                }

                @include desktop {
                    max-width: pToR(1105);
                }

                .return-to-dealer-component-title {
                    font-size: pToR(32);
                    font-family: $ford-f1-font-regular;
                    color: $charcoal;
                    letter-spacing: normal;
                    line-height: pToR(40);
                    padding-bottom: pToR(16);
                    margin-bottom: pToR(0);
                    @include mobile {
                        padding-top: pToR(8);
                        font-size: pToR(28);
                        line-height: pToR(36);
                    }

                    .brand-lincoln & {
                        font-family: $proxima-nova-regular;
                        padding-bottom: pToR(18);
                    }
                }

                .return-to-dealer-component-subtitle {
                    font-size: pToR(16);
                    font-family: $ford-f1-font-regular;
                    color: $charcoal;
                    letter-spacing: normal;
                    line-height: pToR(24);
                    font-weight: 400;
                    margin-bottom: pToR(0);
                    p {
                        letter-spacing: normal;
                    }

                    .brand-lincoln & {
                        font-family: $proxima-nova-regular;
                    }
                }

                .return-to-dealer-component-items {
                    display: flex;
                    @include mobile {
                        padding-bottom: 0;
                        padding-top: 0;
                        flex-direction: column;
                    }
                    @include tablet {
                        padding-bottom: pToR(32);
                    }
                    .return-to-dealer-component-map {
                        margin-top: pToR(48);
                        margin-bottom: pToR(96);
                        margin-right: pToR(18);
                        overflow: hidden;
                        border-radius: pToR(12);
                        @include mobile {
                            margin-top: pToR(32);
                            margin-bottom: pToR(30);
                            margin-right: 0;
                            max-width: pToR(356);
                            height: pToR(161);
                        }
                        @include tablet {
                            max-width: pToR(356);
                            height: pToR(161);
                        }
                        @include desktop {
                            max-width: pToR(542);
                            height: pToR(161);
                        }
                    }
                    .return-to-dealer-component-address {
                        padding-top: pToR(71);
                        @include mobile {
                            padding-top: 0;
                            padding-bottom: pToR(64);
                        }
                        p {
                            color: $charcoal;
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(14);
                            font-style: normal;
                            letter-spacing: normal;
                            font-weight: 400;
                            line-height: pToR(20);

                            .brand-lincoln & {
                                font-family: $proxima-nova-regular;
                            }
                        }
                        .dealer-name {
                            font-size: pToR(20);
                            font-weight: 500;
                            line-height: pToR(28);

                            .brand-lincoln & {
                                font-family: $proxima-nova-regular;
                            }
                        }
                        .hr-line {
                            height: pToR(1);
                            border-bottom: pToR(1) solid $lightgrey;
                            box-shadow: none;
                            margin-bottom: pToR(8);
                            width: 100%;

                            .brand-lincoln & {
                                margin-bottom: pToR(12);
                            }

                            @include mobile {
                                margin-left: initial;
                                max-width: pToR(320);
                            }

                            @include desktop {
                                width: 100%;
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
