$lincoln-proxima-font-directory: './proxima-nova';
$lincoln-miller-font-directory: './miller';

/* proxima nova regular */
@font-face {
    font-family: 'proxima-nova-regular';
    font-display: swap;
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Reg-webfont.woff2');
    font-weight: normal;
    font-style: normal;
}

/* proxima nova light */
@font-face {
    font-family: 'proxima-nova-light';
    font-display: swap;
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Light-webfont.woff2');
    font-weight: normal;
    font-style: normal;
}

/* proxima nova semibold */
@font-face {
    font-family: 'proximanova-sbold';
    font-display: swap;
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Sbold-webfont.woff2');
    font-weight: 600;
    font-style: normal;
}

/* proxima nova bold */
@font-face {
    font-family: 'proxima-nova-bold';
    font-display: swap;
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Bold-webfont.woff2');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller semi-bold */
@font-face {
    font-family: 'lincolnmillerbsemibold';
    font-display: swap;
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-semibold-webfont.woff2');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller light italic */
@font-face {
    font-family: 'lincolnmillerblightitalic';
    font-display: swap;
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-lightitalic-webfont.woff2');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller light */
@font-face {
    font-family: 'lincolnmillerblight';
    font-display: swap;
    src: url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Light.woff2');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller roman */
@font-face {
    font-family: 'lincolnmillerbroman';
    font-display: swap;
    src: url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Roman.woff2');
    font-weight: normal;
    font-style: normal;
}

//TYPEFACES
$proxima-nova-light: 'proxima-nova-light', Arial, Helvetica, sans-serif;
$proxima-nova-regular: 'proxima-nova-regular', Arial, Helvetica, sans-serif;
$proxima-nova-bold: 'proxima-nova-bold', Arial, Helvetica, sans-serif;
$proxima-nova-semibold: 'proximanova-sbold', Arial, Helvetica, sans-serif;

$lincoln-miller-b-semibold: 'lincolnmillerbsemibold', Arial, Helvetica,
    sans-serif;
$lincoln-millerb-lightitalic: 'lincolnmillerblightitalic', Arial, Helvetica,
    sans-serif;
$lincoln-millerb-light: 'lincolnmillerblight', Arial, Helvetica, sans-serif;
$lincoln-millerb-roman: 'lincolnmillerbroman', Arial, Helvetica, sans-serif;
