//colors added for lincoln brand site
$brand-primary: #6b767b;
$brand-secondary: #324047;
$brand-border: #f26147;
$brand-white: #fff;
$brand-header-border: #979797;
$brand-black: #22292b;
$brand-light-blue: #2861a4;
$brand-scrollbar: #233338;
$brand-light-scrollbar: #233337;
$brand-scrollbar-thumb: #22292b;
$brand-lds-color-overlay: rgba(0, 0, 0, 0.4);
//added for footer
$brand-dark-grey: #233338;
$brand-offwhite: #fff5ee;

$brand-while-smoke: #efefef;
$brand-link-water: #cacdcf;

// lincoln brand site gray colors
$brand-gray1: #f6f7f7;
$brand-gray2: #ecedee;
$brand-gray3: #e0e0e0;
$brand-gray4: #919191;
$brand-mild-gray1: #717171;
$brand-mild-gray2: #54565b;
$dark-gray: #6e6e6e;

// lincoln brand alerts
$brand-success: #219653;
$brand-attention: #fcded8;
$brand-light-error: #b00020;
$brand-dark-error: #eb5757;
$brand-light-success: #d7e9de;
$brand-light-success-background: #d6e9df;

// Lincoln Reimagine colors
$lincoln-reimagine-primary-dark: #485b65;
$lincoln-reimagine-primary-dark-hover: #22292b;
